import './style/index.scss';

import React from 'react';
import { useActivityDOM, useCreation } from 'pajk-design';
import { Page, SafeArea } from '@pajk-design/biz-ui';
import PajkLaunch from "@/shared/components/PajkLaunch";

import Search from "./components/Search";
import HomeDoctor from "./components/HomeDoctor";
import Balls from "./components/Balls/Balls";
import RollerBalls from "./components/RollerBalls";
import GoodArticles from "./components/GoodArticles";
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import Seckill from "./components/Seckill";
import Specialist from "./components/Specialist";
import ArticleInfiniteList from "./components/ArticleInfiniteList";
import useHomePage from "./hooks/useHomePage";
import { genSpmB, setDOMAttrs } from "@/shared/libs/spm";

export default function Home() {
    const homePage = useHomePage();
    const wrapper = useActivityDOM();

    useCreation(() => {
        setDOMAttrs(
            wrapper,
            genSpmB('homepage')
        );
    }, []);

    console.log('home', homePage);

    return <>
        <PajkLaunch code="invite-comp-galaxy-WAP" />
        <Page
            className="m-home"
            mainClassName="m-home-main"
            header={null}
            before={
                <Search
                    {...homePage.search}
                    {...homePage.service}
                />
            }
            reachBottomOffset={200}
        >
            {
                homePage.state.complete
                    ? <>
                        <HomeDoctor {...homePage.homeDoctor} />
                        <Balls list={homePage.bigBalls} />
                        <RollerBalls list={homePage.rollerBalls} />
                        <GoodArticles {...homePage.goodArticles} />
                        <Specialist {...homePage.departments} />
                        <Seckill {...homePage.seckill} />
                        <ArticleInfiniteList title={homePage.walterfall?.storeyContents?.title} />
                    </>
                    : null
            }
            <Copyright />
            <SafeArea.Bottom />
        </Page>
        <Footer list={homePage.footer} />
    </>;
}