import './style.scss';
import React from "react";
import { Footer, getTFSImageUrl, transitionTo } from "@pajk-design/biz-ui";
import { HomeIcon, MyIcon } from "@/shared/components/ImageIcon";
import { Text } from "pajk-design";
import { genSpmC, genSpmCSub } from "@/shared/libs/spm";

type HomeFooterProps = {
    list?: any[];
}

const HomeFooter = ({ list }: HomeFooterProps) => {
    return <Footer className="m-home-footer"
        {...genSpmC('bottombar')}
    >
        <ul className="flex w_1x">
            <li className="flex_1"
                {...genSpmCSub('homepage_tab')}
            >
                <p className="icon">
                    <HomeIcon current />
                </p>
                <Text fontSize={10} color="text" className="text">首页</Text>
            </li>
            {
                list?.map((item) => {
                    return <li className="flex_1"
                        key={item.title}
                        onClick={() => {
                            item.url && transitionTo(item.url);
                        }}
                        {...genSpmCSub(item.businessCode == 'm_footer_my' ? 'my_tab' : item.businessCode)}
                    >
                        <a href={item.url}>
                            <p className="icon">
                                <MyIcon {...(item.imgSrc ? { src: getTFSImageUrl(item.imgSrc)! } : null)} />
                            </p>
                            <Text fontSize={10} className="text">{item.title}</Text>
                        </a>
                    </li>;
                })
            }
        </ul>
    </Footer>;
};

export default HomeFooter;