import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { loadJs } from "@pajk-design/utils";
import { useDOMNodeChange } from "pajk-design";

import './style.scss';
// import { usePageScrollEvent } from "@pajk-design/biz-ui";

/** 参数都是可选项，需要自定义的时候再传入 */
type AppLaunchProps = {
    /** ribbon配置code */
    code?: string;
    /** 标题 */
    title?: string;
    /** 副标题 */
    subTitle?: string;
    /** logo tfs地址 */
    logo?: string;
    /** 按钮文案 */
    btnTxt?: string;
    /** 是否展示 */
    display?: boolean;
    /** 是否展示关闭按钮 */
    closable?: boolean;
    /** 应用直达链接 */
    openUrl?: string;
    /** 销毁事件 */
    onDestroy?(): any;
};

const PajkLaunchRender = React.memo((props: AppLaunchProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(true);
    const onClose = () => {
        setVisible(false);
    };
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        loadJs(`//${location.hostname.indexOf('.jk.cn') != -1 ? 'www.jk.cn' : 'www.test.pajkdc.com'}/pajk-launch/static/js/main.js`, 'pajk-launch', () => {
            setLoaded(true);
        });
    }, []);

    useLayoutEffect(() => {
        if (visible && loaded) {
            // 每次执行会根据参数变更重新渲染dom
            const launcher = window.PAJKAppLaunch.render({
                ...props,
                onClose
            }, ref.current);
            // 组件unmount后再销毁
            return () => launcher.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, loaded]);

    useDOMNodeChange((element) => {
        const offsetHeight = (element as HTMLElement).offsetHeight;
        if (offsetHeight == 0 && loaded) {
            setVisible(false);
        }
    }, ref);

    // const isSetTransitionRef = useRef(false);
    // usePageScrollEvent("scroll", (e) => {
    //     if (!isSetTransitionRef.current) {
    //         ref.current?.classList.add('m-pajk-launch-transition');
    //         isSetTransitionRef.current = true;
    //     }
    //     if (e.y > 0) {
    //         ref.current?.classList.add('m-pajk-launch-hide');
    //     } else {
    //         ref.current?.classList.remove('m-pajk-launch-hide');
    //     }
    // });
    console.log(visible);
    if (!visible) {
        return null;
    }
    return <div
        ref={ref}
        className="m-pajk-launch"
    ></div>;
});

export default PajkLaunchRender;