
import React, { CSSProperties } from 'react';

export type ImageIconProps = {
    className?: string;
    style?: CSSProperties;
    src: string;
    width: number;
    onClick?(): void;
} & SpmProps;

const ImageIcon = ({
    src,
    style,
    width,
    ...moreProps
}: ImageIconProps) => {
    return <img
        alt=""
        {...moreProps}
        style={{
            ...style,
            width
        }}
        src={src}
    />;
};

export type ImplProps = Omit<ImageIconProps, 'src' | 'width'> & {
    src?: string;
    width?: number;
};

type TImageIcon = typeof ImageIcon & {
    create(defaultProps: {
        src: string,
        width: number,
    }): (props: ImplProps) => JSX.Element;
}

(ImageIcon as TImageIcon).create = (defaultProps: {
    src: string,
    width: number,
}) => {
    return (props: ImplProps) => {
        return <ImageIcon
            {...defaultProps}
            {...props}
        />;
    };
};

export default ImageIcon as TImageIcon;