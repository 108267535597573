import { gw } from "@pajk-design/biz-ui";

export function getSeckillAndGoodsByMailNoneLogin(strategyId: string) {
    return gw.request('hummer.getSeckillAndGoodsByMailNoneLogin', false, {
        strategyId
    });
}

export function getSeckillAndGoodsByMail(strategyId: string) {
    return gw.request('hummer.getSeckillAndGoodsByMail', true, {
        strategyId
    }, {
        autoLogin: false
    });
}

export function getStatisticsOfDeptByLoginNone() {
    return gw.request('hummer.getStatisticsOfDeptByLoginNone', false);
}
