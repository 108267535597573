import { useRequest } from "pajk-design";
import { useEffect, useMemo, useState } from "react";

import { getStoreyAdList } from "@/shared/apis/adRouter";
import useGoodArticles from "./useGoodArticles";
import useSeckill from "./useSeckill";
import { useUser } from "@pajk-design/biz-ui";
import useDepartments from "./useDepartments";

const useComponent = (code: string, homeComponentList: any[]) => {
    return useMemo(() =>
        homeComponentList?.find(item => item.storeyContents.boothCode == code || item.storeyContents.businessCode == code),
        [homeComponentList, code]
    );
};

const useSearch = (searchData: any) => {
    return useMemo(() => {
        const data = searchData?.ads?.[0].creatives?.[0];
        return data
            ? {
                searchUrl: data.landingPage,
                positionCode: searchData.storeyContents.businessCode,
                placeholder: data.title
            }
            : null;
    }, [searchData]);
};

const useServiceIcon = (serviceData: any) => {
    return useMemo(() => {
        const data = serviceData?.ads?.[0].creatives?.[0];
        return data
            ? {
                serviceUrl: data.landingPage,
                serviceIcon: data.materials?.[0]?.url
            }
            : null;
    }, [serviceData]);
};

const useImageTextList = (sourceData: any) => {
    return useMemo(() => {
        const creatives = sourceData?.ads?.[0].creatives;
        return creatives
            ? creatives.map(creative => {
                return {
                    id: creative.traceId,
                    url: creative.landingPage,
                    title: creative.title,
                    subTitle: creative.subTitle,
                    imgSrc: creative.materials?.[0]?.url
                };
            })
            : null;
    }, [sourceData]);
};

const useSpecialist = (specialistTitle: any, specialist: any) => {
    return useMemo(() => {
        return specialistTitle
            ? {
                ...specialistTitle.storeyContents,
                boothCode: specialist.storeyContents.boothCode
            }
            : null;
    }, [specialistTitle, specialist]);
};

const useHomePage = () => {
    const {
        userInfo,
        getUserInfo
    } = useUser();

    const [userReady, setUserReady] = useState(false);

    useEffect(() => {
        getUserInfo()
            .then(() => {
                setUserReady(true);
            });
    }, [getUserInfo]);

    const [homeState,] = useRequest(getStoreyAdList, userReady, useMemo(() => ({
        pageCode: 'm_index'
    }), []));

    const homeComponentList = useMemo(() => homeState.data?.storeys.map((item: any) => {
        return {
            ...item,
            storeyContents: JSON.parse(item.storeyContents)
        };
    }), [homeState]);

    const search = useComponent("KC001", homeComponentList);
    const service = useComponent("SP132", homeComponentList);
    const homeDoctor = useComponent("m_home_doctor", homeComponentList);
    const bigBalls = useComponent("SP007", homeComponentList);
    const rollerBalls = useComponent("SP112", homeComponentList);
    const goodArticles = useComponent("SP042", homeComponentList);
    const specialistTitle = useComponent("m_specialist", homeComponentList);
    const specialist = useComponent("SP130", homeComponentList);
    const seckill = useComponent("m_seckill", homeComponentList);
    const walterfall = useComponent("m_falls", homeComponentList);

    const footer = useMemo(() => {
        return homeComponentList?.filter((item: any) => item.storeyContents.businessCode?.startsWith('m_footer'))
            .map((item: any) => {
                return {
                    title: item.storeyName,
                    ...item.storeyContents
                };
            });
    }, [homeComponentList]);

    const specialistInfo = useSpecialist(specialistTitle, specialist);

    return {
        userInfo,
        state: homeState,
        homeComponentList,
        search: useSearch(search),
        service: useServiceIcon(service),
        homeDoctor: homeDoctor ? {
            ...homeDoctor.storeyContents
        } : null,
        bigBalls: useImageTextList(bigBalls),
        rollerBalls: useImageTextList(rollerBalls),
        goodArticles: useGoodArticles(goodArticles),
        departments: useDepartments(userInfo, userReady && specialistInfo),
        seckill: useSeckill(seckill?.storeyContents),
        walterfall,
        footer
    };
};

export default useHomePage;