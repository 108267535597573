import { OUT_BIZ_TYPE } from "@pajk-design/biz-ui";
import { getSearchParam } from "@pajk-design/utils";

// 用于打点业务传参
export const baseLoggerData = {
    channel: OUT_BIZ_TYPE || 'PAJK', // 业务方 默认主客
    source: getSearchParam('source') || '', // 来源 默认空
};

/**
 * 自动打点B域(页面)生成模板
 * @param {string} name - 页面名
 * @param {object} cntObj - 业务数据
 * @param {object} spmOptions - SPM拓展字段
 */
export const genSpmB = (name, cntObj = {}, spmOptions = {}) => {
    cntObj = {
        ...baseLoggerData,
        ...cntObj
    };
    const dataObj = {
        'data-spm-page': name,
        'data-spm-cnt': JSON.stringify(cntObj),
        ...spmOptions,
    };
    return dataObj;
};

/**
 * 自动打点C域(Module)生成模板
 * @param {string} name - 页面名
 * @param {object} cntObj - 业务数据
 * @param {object} spmOptions - SPM拓展字段
 */
export const genSpmC = (name, cntObj = {}, spmOptions = {}) => {
    const dataObj = {
        'data-spm': name,
        'data-spm-type': 'content',
        'data-spm-cnt': JSON.stringify({ ...baseLoggerData, ...cntObj }),
        // 'data-spm-pos': 1,
        ...spmOptions,
    };
    return dataObj;
};

/**
 * 自动打点C域子模块(C1)生成模板
 * @param {string} name - 页面名
 * @param {object} cntObj - 业务数据
 * @param {object} spmOptions - SPM拓展字段
 */
export const genSpmCSub = (name, cntObj = {}, spmOptions = {}) => {
    const dataObj = {
        'data-spm-sub': name,
        'data-spm-type': 'content',
        'data-spm-cnt': JSON.stringify({ ...baseLoggerData, ...cntObj }),
        // 'data-spm-pos': 1,
        ...spmOptions,
    };
    return dataObj;
};


export const genSpmContentSub = (name, cntObj = {}, spmOptions = {}) => {
    const dataObj = {
        'data-spm-content-sub': name,
        'data-spm-type': 'content',
        'data-spm-cnt': JSON.stringify({ ...baseLoggerData, ...cntObj }),
        // 'data-spm-pos': 1,
        ...spmOptions,
    };
    return dataObj;
};

export const setDOMAttrs = (dom: HTMLElement, attrs: any) => {
    Object.keys(attrs)
        .forEach(key => {
            dom.setAttribute(key, attrs[key]);
        });
};