import ImageIcon, { ImplProps } from "./ImageIcon";
import my from "../../images/my@3x.png";
import myCurrent from "../../images/my_current@3x.png";
import React from "react";

const DefaultIcon = ImageIcon.create({
    src: my,
    width: 24
});

const CurrentIcon = ImageIcon.create({
    src: myCurrent,
    width: 24
});

const MyIcon = ({
    current,
    ...props
}: ImplProps & {
    current?: boolean;
}) => {
    return React.createElement(current ? CurrentIcon : DefaultIcon, props);
};

export default MyIcon;