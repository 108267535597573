import { genSpmC } from "@/shared/libs/spm";
import { transitionTo } from "@pajk-design/biz-ui";
import { Right } from "@pajk-design/icons";
import { Divider } from "pajk-design";
import React from 'react';

import './style.scss';

type HomeDoctorProps = {
    title: string;
    subTitle: string;
    url: string;
    buttonTitle: string;
}

const HomeDoctor = ({
    title,
    subTitle,
    url,
    buttonTitle
}: HomeDoctorProps) => {
    if (!title) return null;
    return <div className="flex m-home-doctor"
        onClick={() => {
            transitionTo(url);
        }}
        {...genSpmC('familydoc')}
    >
        <div className="title">{title}</div>
        <Divider type="vertical" />
        <b className="flex_1 subtitle">{subTitle}</b>
        <a
            className="go"
            href={url}
        >
            <span>{buttonTitle}</span>
            <Right size={12} />
        </a>
    </div>;
};

export default HomeDoctor;