import { useMemoizedFn, useRequest } from "pajk-design";

import { getSeckillAndGoodsByMailNoneLogin } from "@/shared/apis/hummer";
import { useState } from "react";

type FloorInfo = {
    title: string;
    subTitle: string;
    buttonTitle: string;
}

const useSeckill = (goods: FloorInfo | null) => {
    const [id, setId] = useState(0);
    const [seckillState] = useRequest(
        // userInfo?.id ? getSeckillAndGoodsByMail : getSeckillAndGoodsByMailNoneLogin,
        getSeckillAndGoodsByMailNoneLogin,
        !!goods, goods?.buttonTitle || 'bybt_slid', id
    );

    const onReload = useMemoizedFn(() => {
        setId((id) => {
            return id + 1;
        });
    });

    return goods && (seckillState.data?.mallInfo || seckillState.data?.seckillInfo || seckillState.data?.activityInfo)
        ? {
            goods,
            ...seckillState.data,
            systime: seckillState.stat.systime,
            onReload
        }
        : null;
};

export default useSeckill;