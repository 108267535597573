import { getDepartmentList } from "@/shared/apis/adRouter";
import { getStatisticsOfDeptByLoginNone } from "@/shared/apis/hummer";
import { useRequest } from "pajk-design";
import { useMemo } from "react";


const useDepartments = (_userInfo: any, specialistInfo: any) => {
    const boothCode = specialistInfo?.boothCode;
    const runRequest = !!boothCode;
    const [statistics] = useRequest(getStatisticsOfDeptByLoginNone, runRequest);
    const [departments] = useRequest(getDepartmentList, runRequest, useMemo(() => ({
        boothCode
    }), [boothCode]));

    return useMemo(() => ({
        ...specialistInfo,
        statistics: statistics.data?.statisticsList?.[0],
        list: departments.data?.departments
    }), [departments.data, specialistInfo, statistics.data]);
};

export default useDepartments;