import ImageIcon, { ImplProps } from "./ImageIcon";
import home from "../../images/home@3x.png";
import homeCurrent from "../../images/home_current@3x.png";
import React from "react";

const DefaultIcon = ImageIcon.create({
    src: home,
    width: 24
});

const CurrentIcon = ImageIcon.create({
    src: homeCurrent,
    width: 24
});

const HomeIcon = ({
    current,
    ...props
}: ImplProps & {
    current?: boolean;
}) => {
    return React.createElement(current ? CurrentIcon : DefaultIcon, props);
};

export default HomeIcon;