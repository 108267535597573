import './style.scss';
import React from 'react';
import { SearchBar } from "pajk-design";

import { ServiceIcon } from "@/shared/components/ImageIcon";
import { getTFSImageUrl } from "@pajk-design/biz-ui";
import { genSpmC, genSpmCSub } from "@/shared/libs/spm";

type SearchProps = {
    placeholder?: string;
    searchUrl?: string;
    serviceUrl?: string;
    serviceIcon?: string;
};

const Search = ({
    placeholder,
    searchUrl,
    serviceUrl,
    serviceIcon
}: SearchProps) => {
    return <div
        className="flex m-home-search"
        {...genSpmC('topbar')}
    >
        <a
            className="fx_1 dp_b"
            href={searchUrl}
            {...genSpmCSub('search')}
        >
            <SearchBar
                type="primary"
                placeholder={placeholder}
            />
        </a>
        <a
            className="m-service"
            href={serviceUrl}
            {...genSpmCSub('servicecenter')}
        >
            <ServiceIcon
                {...(serviceIcon
                    ? {
                        src: getTFSImageUrl(serviceIcon)!
                    }
                    : null
                )}
            />
        </a>
    </div>;
};

export default Search;