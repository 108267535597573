import './style.scss';

import React from 'react';
import { Carousel, Tag } from "pajk-design";
import { genSpmC } from "@/shared/libs/spm";

type GoodArticleProps = {
    title?: string;
    infos?: {
        infoId: number;
        title: string;
        link: string;
        type: string;
        eventParams: any;
    }[]
}

const Types = {
    VIDEO: '视频',
    HL: '资讯',
    TOPIC: '专题'
};

const GoodArticles = ({ title, infos }: GoodArticleProps) => {
    if (!infos?.length) return null;

    return <div
        className="m-good-articles"
    >
        <b className="title">{title}</b>
        <Carousel
            direction="vertical"
            loop
            autoplay
            gesture={false}
        >
            {
                infos?.map((info, i) => {
                    return <a
                        key={info.infoId}
                        className="flex m-good-articles-item"
                        href={info.link}
                        {...genSpmC('healthselect', {
                            contentId: info.infoId,
                            ...info.eventParams
                        }, {
                            'data-spm-pos': i + 1
                        })}
                    >
                        <Tag type="warning" outlined>{Types[info.type] || '资讯'}</Tag>
                        <p className="to_e flex_1 text">{info.title}</p>
                    </a>;
                })
            }
        </Carousel>
    </div>;
};

export default GoodArticles;