import './style.scss';
import React from 'react';
import { ScrollView } from "pajk-design";
import { Right } from "@pajk-design/icons";
import { TFSImage, transitionTo } from "@pajk-design/biz-ui";
import { genSpmC, genSpmCSub } from "@/shared/libs/spm";

type DepartmentList = {
    departmentCode: string;
    iconImage: string;
    departmentName: string;
    landingPage: string;
    departmentTrace: any;
}[];

type SpecialistProps = {
    boothCode?: string;
    title?: string;
    subTitle?: string;
    url?: string;
    statistics?: {
        consultOrderCount: number;
    };
    list?: DepartmentList
}

const Specialist = ({
    title,
    subTitle,
    url,
    statistics,
    list
}: SpecialistProps) => {
    if (!list?.length || !statistics || list.length < 8) return null;

    // list && (list = list.concat([{
    //     ...list[0],
    //     departmentName: '妇科'
    // }, {
    //     ...list[1],
    // }]).concat(list)
    //     .concat(list)
    //     .concat(list)
    //     .concat(list)
    //     .concat(list)
    //     .concat(list)
    //     .concat(list)
    //     .concat([{
    //         ...list[0],
    //         departmentName: '妇科'
    //     }, {
    //         ...list[1],
    //     }]));

    const departments = list.slice(0, Math.floor(list.length / 2) * 2);
    const half = departments.length / 2;
    const left = departments.slice(0, half);
    const right = departments.slice(half);

    return <div
        className="m-specialist"
        {...genSpmC('department')}
    >
        <div
            className="hd flex"
            {...genSpmCSub('title')}
        >
            <b className="title">{title}</b>
            <p className="flex_1 serv">累计服务<span>{statistics?.consultOrderCount.toLocaleString()}</span>人</p>
            <a
                className="all_btn"
                href={url}
            >
                <span>{subTitle}</span>
                <Right size={12} color="#666" />
            </a>
        </div>
        <ScrollView
            className="bd"
            scrollY={false}
            scrollX
            noScrollbar
        >
            <Departments list={left} />
            <Departments list={right} />
        </ScrollView>
    </div>;
};

const Departments = ({
    list
}: {
    list?: DepartmentList | null
}) => {
    return <div className="m-specialist-departments">
        {
            list?.map((item) => {
                return <a
                    key={item.departmentCode}
                    className="flex m-specialist-dep-item"
                    onClick={() => {
                        transitionTo(item.landingPage);
                    }}
                    {...genSpmCSub('room', item.departmentTrace)}
                >
                    <TFSImage className="img" src={item.iconImage} />
                    <p className="title to_e">{item.departmentName.length > 2
                        ? item.departmentName
                        : item.departmentName.split('')
                            .reduce((res, curr, i) => res.concat(curr)
                                .concat(i == 0 ? [<span key={i}>一</span>] : []), [] as any[])}</p>
                </a>;
            })
        }
    </div>;
};

export default Specialist;