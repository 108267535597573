import './style.scss';
import React from 'react';
import { TFSImage } from "@pajk-design/biz-ui";
import { Button, Carousel, Countdown } from "pajk-design";
import { genSpmC, genSpmCSub } from "@/shared/libs/spm";

type SeckillProps = {
    systime?: number;
    onReload?(): void;
    goods?: any;
    seckillInfo?: any;
    mallInfo?: {
        recommendSpuInfos?: any[]
    },
    activityInfo?: any;
};

const Seckill = ({
    goods,
    mallInfo,
    activityInfo,
    seckillInfo,
    onReload
}: SeckillProps) => {
    if (!goods || !seckillInfo?.seckillSpuDTOList?.length || !mallInfo?.recommendSpuInfos?.length || !activityInfo?.imgTfsKey || !activityInfo?.title) return null;
    const hasSeckill = seckillInfo.nowTime && seckillInfo.startTime && seckillInfo.endTime
        && seckillInfo.nowTime > seckillInfo.startTime && seckillInfo.nowTime < seckillInfo.endTime;
    return <div className="m-seckill"
        {...genSpmC('mall_floor')}
    >
        <div className="m-seckill-item">
            <b className="title" {...genSpmCSub('title_sale')}>{seckillInfo.title}</b>
            {
                hasSeckill
                    ? <Countdown
                        className="countdown"
                        leftTime={seckillInfo.endTime - seckillInfo.nowTime}
                        onEnd={onReload}
                    />
                    : <div className="subtitle">{seckillInfo.subTitle}</div>
            }
            <ProdCarousel
                url={goods.url}
                spmKey="SPU_sale"
                autoplay
                items={seckillInfo.seckillSpuDTOList.slice(0, 4)}
            />
        </div>
        <div className="m-seckill-item">
            <b className="title" {...genSpmCSub('title_sub')}>{goods.title}</b>
            <p className="subtitle">{goods.subTitle}</p>
            <ProdCarousel
                spmKey="SPU_sub"
                items={mallInfo.recommendSpuInfos}
            />
        </div>
        <a
            className="m-seckill-item dp_b"
            href={activityInfo.url}
        >
            <b className="title">{activityInfo.title}</b>
            <p className="subtitle">{activityInfo.subTitle}</p>
            <div className="con">
                <TFSImage className="img" src={activityInfo.imgTfsKey} {...genSpmCSub('pic_sign', { title: activityInfo.title })} />
                <Button
                    type="primary"
                    size="small"
                    {...genSpmCSub('button_sign', { title: activityInfo.isSign ? activityInfo.buttonStr : activityInfo.disButtonStr })}
                >{activityInfo.isSign ? activityInfo.buttonStr : activityInfo.disButtonStr}</Button>
            </div>
        </a>
    </div>;
};


export default Seckill;

type ProdCarouselProps = {
    url?: string;
    spmKey: string;
    items: any[];
    className?: string;
    autoplay?: boolean;
}

const ProdCarousel = ({
    url,
    spmKey,
    items,
    autoplay = false
}: ProdCarouselProps) => {
    return <Carousel
        className="m-seckill-prd-carousel con"
        loop
        autoplay={autoplay}
        duration={1000}
        // gesture={false}
        customTransform={({
            translate,
            percent,
            items,
            index,
            nextIndex
        }) => {
            const current = items[index];
            if (translate == 0) {
                const changer = items[nextIndex];
                changer && (changer.style.transform =
                    changer.style.left =
                    changer.style.opacity = '');
                current.style.transform =
                    current.style.opacity =
                    current.style.left = '';
            } else {
                let changeIndex = translate < 0 ? index + 1 : index - 1;
                changeIndex = changeIndex >= items.length ? 0 : changeIndex < 0 ? items.length - 1 : changeIndex;

                const per = Math.abs(percent);
                const changer = items[changeIndex];
                current.style.opacity = per < 50 ? 1 - (per / 50) : 0;
                current.style.transform = `scale(${per < 50 ? 1 - (per / 50) : 0})`;
                current.style.left = '0px';

                changer.style.opacity = per < 50 ? 0 : ((per - 50) / 50);
                changer.style.transform = `scale(${Math.abs(percent) / 100})`;
                changer.style.left = '0px';
            }
        }}
    >
        {
            items.map((item) => {
                const spuId = item.id || item.spuId;
                const picture = item.picture.split('|')[0];
                const host = location.hostname.includes('.dev.pajkdc.com')
                    ? 'www.dev.pajkdc.com'
                    : location.hostname.includes('.test.pajkdc.com')
                        ? 'www.test.pajkdc.com'
                        : location.hostname.includes('.pre.jk.cn')
                            ? 'wwww.pre.jk.cn'
                            : 'www.jk.cn';
                const itemUrl = item.recommendSpuAttr?.url || url || `//${host}/shop/#/item/${spuId}`;
                return <a
                    key={spuId}
                    className="dp_b with-mask"
                    href={itemUrl}
                    {...genSpmCSub(spmKey, {
                        spuid: spuId,
                        strategy: item.recommendSpuAttr?.strategy
                    })}
                >
                    <TFSImage className="img" src={picture} />
                    <div className="price">{item.prefixPrice}¥{item.price / 100}</div>
                </a>;
            })
        }
    </Carousel>;
};