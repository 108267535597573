import React from "react";

import { app, App, Activity } from "pajk-design";
import { BizConfigProvider, Wechat } from "@pajk-design/biz-ui";

import '@/shared/style/index.scss';

import Home from "./app/home";

// 若要启用全部jsBridge，则移除`BizConfigProvider`的`jsBridges`属性，调用 `enableAllBridges()` 方法
// import { enableAllBridges } from "@pajk-design/biz-ui";
// enableAllBridges();

// 配置微信JSSDK（调用Wechat.ready或用到相关api时才会加载和注册）
// Wechat.setup({
//     appId: process.env.REACT_APP_WX_APP_ID || (location.host.indexOf('.jk.') != -1 ? 'wx02710dae0d7fec8b' : "wx5b212a2e26249a25"),
//     jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
// });

app.start(
    <BizConfigProvider
        config={{
            /** rem基础大小 */
            remBase: 100,
            /** 根据url上isImmsersive参数自动启用沉浸式的页面样式，默认true */
            immsersive: true,
            /** 弹框等场景自动展示/隐藏返回按钮，主客默认true */
            // autoShowHideBackButton: IS_PAJK_APP,
            /** 是否根据环境自动隐藏顶bar */
            // autoHideNavBar: true,
            /** 是否在设置<Page header={{documentTitle|title}}...>时自动设置document.title */
            // autoSetDocumentTitle: !IS_PAJK_APP,
        }}
        jsBridges={[
            // 微信SDK
            Wechat
        ]}
    >
        <App>
            <Activity path="/" component={Home} />
            {/* <Activity path="/test" component={Test} />
            <Activity
                path="/test/\d+:id"
                component={React.lazy(() => import("./app/Test"))}
            /> */}
        </App>
    </BizConfigProvider>
);

