import './style.scss';

import React from 'react';
import { TFSImage } from "@pajk-design/biz-ui";
import { genSpmC } from "@/shared/libs/spm";

type BallProps = {
    id: string;
    imgSrc: string;
    subTitle: string;
    title: string;
    url: string;
}

type BallsProps = {
    list?: BallProps[];
}

const Balls = ({
    list
}: BallsProps) => {
    if (!list?.length || list.length < 3) return null;

    return <div className="flex m-balls">
        {
            list.map((item, i) => {
                return <a
                    key={item.id}
                    className="m-balls-item"
                    href={item.url}
                    {...genSpmC('guideball_big', {
                        title: item.title
                    }, {
                        'data-spm-pos': i + 1
                    })}
                >
                    <TFSImage src={item.imgSrc} className="img" />
                    <p className="title">{item.title}</p>
                    <p className="subTitle">{item.subTitle}</p>
                </a>;
            })
        }
    </div>;
};

export default Balls;