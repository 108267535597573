import { gw } from "@pajk-design/biz-ui";

/**
 * 健康精选
 * http://doc.pajk-ent.com/pages/viewpage.action?pageId=139746846
 * @example
 * {"pageCode":"App_NewHome_HealthChoiceFeed","adCode":"","sceneCode":"pajk_mpage_omnibus","ids":[],"infos":[]}
 */
export function getOmnibusInfoV2(req: {
    pageCode: string;
    adCode?: string;
    sceneCode: string;
    ids?: number[];
    infos?: [];
}) {
    return gw.request('infox.getOmnibusInfoV2', true, {
        req
    }, {
        autoLogin: false
    });
}

export function getOmnibusInfoV3(req: {
    pageCode: string;
    adCode?: string;
    sceneCode: string;
    ids?: number[];
    infos?: [];
}) {
    return gw.request('infox.getOmnibusInfoV3', false, {
        req
    });
}

/**
 * 首页信息流详情
 * @example
 * pageCode: INFOX_RECOMMEND_LIST, sceneCode, channelId: 0, pageNo: 1
 * resp: data.contentInfoDTOS
 */
export function listRecommendInfo(req: {
    pageNo: number;
    routeCode: string;
    pageCode: string;
    sceneCode: string;
    channelId: number;
}) {
    return gw.request('infox.listRecommendInfo', false, {
        req
    });
}