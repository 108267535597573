import './style.scss';
import React, { useMemo } from 'react';
import { Divider, InfiniteList, List } from "pajk-design";
import { TFSImage, useUser } from "@pajk-design/biz-ui";
import { listRecommendInfo } from "@/shared/apis/infox";
import { genSpmC, genSpmCSub } from "@/shared/libs/spm";

type ArticleInfiniteListProps = {
    title: string;
}

const ArticleInfiniteList = ({ title }: ArticleInfiniteListProps) => {
    const { userId, isLogin } = useUser();
    const isLoginPromise = useMemo(() => userId ? true : isLogin(), [userId, isLogin]);
    return <div className="m-article-inflist"
        {...genSpmC('infolist')}
    >
        <div className="hd" {...genSpmCSub('title', { title })}>{title}</div>
        <InfiniteList
            request={async (lastData) => {
                const didLogin = await isLoginPromise;
                const pageNo = lastData?.nextId as number || 1;
                const res = await listRecommendInfo({
                    pageNo,
                    routeCode: 'MJK',
                    pageCode: 'INFOX_RECOMMEND_LIST',
                    sceneCode: 'App_NewHome_ContentFeed',
                    channelId: 0
                });
                return {
                    list: res.data?.contentInfoDTOS,
                    nextId: pageNo + 1,
                    success: res.success && res.data?.success,
                    // 登录状态无所谓分页
                    hasMore: didLogin ? pageNo < 50 : res.data?.hasNext,
                };
            }}
            noMoreText="已经到底啦"
            // loadMoreText="已经到底啦"
            item={({
                contentInfoDTO,
                publisherDTO,
                spmContent
            }) => {
                return <List.Item
                    htmlType="a"
                    className="m-article-inflist-item"
                    divider
                    key={contentInfoDTO.id}
                    after={
                        <div
                            className="img with-mask"
                        >
                            <TFSImage
                                src={contentInfoDTO.pic}
                            />
                            {contentInfoDTO.type == 'VIDEO' && <div className="play_icon"></div>}
                        </div>
                    }
                    href={contentInfoDTO.link}
                    {...genSpmCSub('content', {
                        content_type: contentInfoDTO.type,
                        content_id: contentInfoDTO.id,
                        ...spmContent
                    })}
                >
                    <p className="title to_e2">{contentInfoDTO.title}</p>
                    <div className="subtitle to_e">
                        <span>{publisherDTO.authorName}</span>
                        <span>{publisherDTO.deptName}</span>
                        {
                            !!publisherDTO.jobTitle && <Divider type="vertical" />
                        }
                        <span>{publisherDTO.jobTitle}</span>
                    </div>
                </List.Item>;
            }}
        ></InfiniteList>
    </div>;
};

export default ArticleInfiniteList;