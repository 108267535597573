import { useMemo } from "react";
import { useRequest } from "pajk-design";

import { getOmnibusInfoV3 } from "@/shared/apis/infox";

type FloorInfo = {
    storeyName: string;
}

const useGoodArticles = (floorInfo: FloorInfo) => {
    const [goodArticles] = useRequest(getOmnibusInfoV3, !!floorInfo, useMemo(() => ({
        pageCode: 'pajk_mpage_omnibus',
        sceneCode: 'App_NewHome_HealthChoiceFeed'
    }), []));
    const infos = goodArticles.data?.omnibusFrameVOS?.[0]?.infos;

    return floorInfo && infos?.length
        ? {
            title: floorInfo.storeyName,
            infos: infos
        }
        : null;
};

export default useGoodArticles;