
import './style.scss';

import React from 'react';

const Copyright = () => {
    return <div className="m-copyright">
        <div className="m-copyright-desc">
            <p>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502000566">
                    <img alt="" src="https://srv.jk.cn/jk-partial/images/record_number_icon.png" style={{ verticalAlign: 'middle' }} />&nbsp;粤公网安备44030502000566号</a>
                &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn">粤ICP备17022768号</a>
            </p>
            <p>深圳市前海深港合作区前湾一路1号A栋201室&nbsp;021-38647044</p>
            <a href="https://szcert.ebs.org.cn/58858ea4-fb93-42aa-b36b-4ae75a35bae8">
                <span>工商电子标识</span>
            </a>
            <span style={{
                display: "inline-block",
                marginLeft: 16
            }}></span>
            <a href="https://www.jk.cn/common-protocol/index.html?code=pc_private">
                <span>隐私政策</span>
            </a>
            <p>医疗器械网络交易服务第三方平台备案凭证：<br /><a href="https://jkcdn.pajk.com.cn/image/T1UoYQ3TAj1RCvBVdK.jpg">（粤）网械平台备字[2018]第00009号</a></p>
            <span>互联网药品信息服务资格证书：<a href="https://jkcdn.pajk.com.cn/image/T1kXb_3sLT1RCvBVdK.jpg">（粤）-经营性-2020-0250</a></span>
            <p style={{textAlign: "center", marginTop: '6px'}}>本网站支持IPv6</p>
        </div>
        <br />
    </div>;
};

export default Copyright;