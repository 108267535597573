import { gw } from "@pajk-design/biz-ui";

/**
 * 获取页面配置
 * @example
 * pageCode: m_index, srcId: 11（M站）, env: 3
 */
export function getStoreyAdList(pageStoreyRequset: {
    pageCode: string;
    device: any;
    srcId?: number;
    env?: number;
}) {
    return gw.request('adRouter.getStoreyAdList', false, {
        pageStoreyRequset: {
            srcId: 11,
            env: 3,
            ...pageStoreyRequset
        }
    });
}


export function getDepartmentList(departmentRequest: {
    boothCode: string;
    srcId?: number;
    env?: number;
}) {
    return gw.request('adRouter.getDepartmentList', false, {
        departmentRequest: {
            srcId: 11,
            env: 3,
            ...departmentRequest
        }
    });
}

export function getDepartments(departmentRequest: {
    boothCode: string;
    srcId?: number;
    env?: number;
}) {
    return gw.request('adRouter.getDepartments', true, {
        departmentRequest: {
            srcId: 11,
            env: 3,
            ...departmentRequest
        }
    }, {
        autoLogin: false
    });
}