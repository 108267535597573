import './style.scss';

import React from "react";
import { Roller } from "pajk-design";
import { TFSImage } from "@pajk-design/biz-ui";
import { genSpmC } from "@/shared/libs/spm";

type BallProps = {
    id: string;
    imgSrc: string;
    subTitle: string;
    title: string;
    url: string;
}

type BallsProps = {
    list?: BallProps[];
}

const RollerBalls = ({
    list
}: BallsProps) => {
    if (!list?.length || list.length < 5) return null;

    return <Roller
        className={"m-roller-balls " + (list.length <= 5 ? 'm-roller-balls-avg' : '')}
    >
        <div className="flex">
            {
                list.map((item, i) => {
                    return <a
                        key={item.id}
                        className="m-roller-balls-item"
                        href={item.url}
                        {...genSpmC('guideball_small', {
                            title: item.title
                        }, {
                            'data-spm-pos': i + 1
                        })}
                    >
                        <TFSImage src={item.imgSrc} className="img" />
                        <p className="title">{item.title}</p>
                    </a>;
                })
            }
        </div>
    </Roller>;
};

export default RollerBalls;